<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="table" :data="tableData" style="width: 100%" max-height="760" :size="$store.getters.size"
                  :default-sort="{prop:'plat',order: 'ascending'}" v-loading="loading" row-key="guid" border>
            <el-table-column label="车号" prop="plat" sortable width="150"></el-table-column>
            <el-table-column label="司机" prop="driverName" width="150"></el-table-column>
            <el-table-column label="状态" prop="status" width="150">
                <template slot-scope="scope">
                    <template v-if="scope.row.status === true">可接单</template>
                    <template v-else-if="scope.row.status === false">不可接单</template>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete"
                               v-if="scope.row.status === false && $app.haveRole('delivery:order_status:delete')"
                               @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import {deleteDriverOrderStatus, getDriverOrderStatus} from '@/api/delivery/delivery'

    export default {
        name: 'DriverOrderStatus',
        data(){
            return {
                queryParams: {
                    warId: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                tableData: [],
                loading: false
            }
        },
        methods: {
            handleDelete(row) {
                this.$confirm('你确定要删除此数据？','提示', {
                    type: 'warning'
                }).then(() => {
                    deleteDriverOrderStatus({'warId':row.warId, 'plat':row.plat}).then(({code}) => {
                        if(code === 0) {
                            this.getTableData();
                            this.$message.success("删除成功！");
                        }
                    })
                }).catch(()=>{})
            },
            getTableData() {
                if (!this.queryParams.warId){
                    this.$message.error('请选择仓库！');
                    return;
                }
                this.loading = true;
                getDriverOrderStatus(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0) {
                        this.tableData = data.map(map => {
                            let ic = parseInt(map.plat)
                            if (!isNaN(ic)) {
                                map.plat = ic
                            }
                            return map
                        });
                    }
                })
            },
            querySearch() {
                this.getTableData();
            }
        }
    }
</script>
