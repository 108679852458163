import request from '@/utils/request'

/** 分页获取配送记录 */
export function getList(params) {
    return request({
        url: '/delivery/getPageList',
        method: 'GET',
        params: params
    })
}

export function getDelivery(params) {
    return request({
        url:'/delivery/getDeliveryDate',
        method:'GET',
        params:params
    })
}

/** 配送超时数据 */
export function getOverrun(warId) {
    return request({
        url: '/delivery/getOverrun/'+warId,
        method: 'GET'
    })
}

export function exportExcelOverrun(data) {
    return request({
        url: '/delivery/exportExcelOverrun',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}


/** 打卡迟到 */
export function driverClockOverrun(warId) {
    return request({
        url: '/delivery/driver/clock/overrun/'+warId,
        method: 'GET'
    })
}


export function exportExcel(data) {
    return request({
        url: '/delivery/exportExcel',
        method: 'get',
        params: data,
        responseType: 'blob'
    })
}

/** 删除一条数据 */
export function remove(guid) {
    return request({
        url: '/delivery/del/'+guid,
        method: 'DELETE'
    })
}

/** 批量删除 */
export function deletes(ids) {
    return request({
        url: '/delivery/removes',
        method: 'DELETE',
        data: ids
    })
}

/** 补货or缺货 */
export function stockList(data) {
    return request({
        url: '/delivery/stockList',
        method: 'GET',
        params: data
    })
}

/** 统计司机送货容器回收信息 */
export function getDriverContainer(params) {
    return request({
        url: '/delivery/getDriverContainer',
        method: 'GET',
        params: params
    })
}

/** 查询司机当天各个客户的容器回收信息 */
export function getDriverContainerDetail(params) {
    return request({
        url: '/delivery/getDriverContainerDetail',
        method: 'GET',
        params: params
    })
}

/** 获取仓库下的客户列表 */
export function getWarehouseCompany(params) {
    return request({
        url: '/delivery/getWarehouseCompany',
        method: 'GET',
        params: params
    })
}

/** 查询客户的容器回收信息 */
export function getCompanyContainer(params) {
    return request({
        url: '/delivery/getCompanyContainer',
        method: 'GET',
        params: params
    })
}

/** 查询司机接单状态 */
export function getDriverOrderStatus(params) {
    return request({
        url: '/delivery/getDriverOrderStatus',
        method: 'GET',
        params: params
    })
}

/** 删除司机接单状态 */
export function deleteDriverOrderStatus(params) {
    return request({
        url: '/delivery/deleteDriverOrderStatus',
        method: 'GET',
        params: params
    })
}
